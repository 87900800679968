import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../layout/Defaultlayout';
import Loader from '../../common/loader/Index';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slice/Userslice';
import {
  getUserNotificationSetting,
  guideTourComplete,
  saveUserNotificationSettings,
} from '../../api/Api';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useTour } from '../../Authcontext';

type checkBoxField =
  | 'subscriptionEmail'
  | 'subscriptionInApp'
  | 'digitalCardEmail'
  | 'digitalCardInApp';

const UserSetting: React.FC = () => {
  // const api_url = import.meta.env.VITE_API_URL;
  const userDetails = useSelector(selectUser);
  const userRole = userDetails?.user.user_role == 'CEO' ? 'user' : 'team_mate';
  const [isSaveInfoSuccess, setIsSaveInfoSuccess] = useState<boolean>(false);
  const [notificationSaveLoading, setNotificationSaveLoading] =
    useState<boolean>(false);
  const [checkedState, setCheckedState] = useState({
    subscriptionEmail: true,
    subscriptionInApp: true,
    digitalCardEmail: true,
    digitalCardInApp: true,
  });
  const [settingDataLoading, setSettingDataLoading] = useState<boolean>(true);

  //  ************* Notification setting functionality *************
  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setSettingDataLoading(true);
      const response = await getUserNotificationSetting();
      if (response?.data.success) {
        const settings = response?.data.data;

        if (settings && settings.length > 0) {
          const updatedCheckedState = {
            subscriptionEmail:
              settings.find(
                (item: any) => item.notification_type === 'subscription',
              )?.email_notification === 1,
            subscriptionInApp:
              settings.find(
                (item: any) => item.notification_type === 'subscription',
              )?.inapp_notification === 1,
            digitalCardEmail:
              settings.find((item: any) => item.notification_type === 'card')
                ?.email_notification === 1,
            digitalCardInApp:
              settings.find((item: any) => item.notification_type === 'card')
                ?.inapp_notification === 1,
          };
          setCheckedState(updatedCheckedState);
        }
      }
    } catch (error) {
      console.error('Error fetching notification settings:', error);
    } finally {
      setSettingDataLoading(false);
    }
  };

  const toggleCheckbox = (checkboxName: checkBoxField) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }));
  };

  const handleNotificationSettings = async (e: any) => {
    e.preventDefault();

    const notificationSettings = [
      {
        notification_type: 'subscription',
        email_notification: checkedState.subscriptionEmail ? 1 : 0,
        inapp_notification: checkedState.subscriptionInApp ? 1 : 0,
        role: userRole,
      },
      {
        notification_type: 'card',
        email_notification: checkedState.digitalCardEmail ? 1 : 0,
        inapp_notification: checkedState.digitalCardInApp ? 1 : 0,
        role: userRole,
      },
    ];

    try {
      setNotificationSaveLoading(true);
      const result = await saveUserNotificationSettings(notificationSettings);
      if (result?.data.success) {
        setIsSaveInfoSuccess(true);
        setTimeout(() => {
          setIsSaveInfoSuccess(false);
        }, 2000);
      }
      fetchSettings();
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setNotificationSaveLoading(false);
    }
  };

  //  ************* website tour *************
  const { tourStep, setTourStep } = useTour();

  useEffect(() => {
    setTimeout(() => {
      // Check if the tour has already been completed
      if (userDetails?.user.tour_guide == 0 && tourStep === 7) {
        startTour();
      }
    }, 300);
  }, [tourStep, checkedState]);

  // Start the tour for Page 1
  const startTour = () => {
    const tour = driver({
      showProgress: true,
      // doneBtnText: 'Next →',
      steps: [
        {
          element: '#subscription-notification',
          popover: {
            title: 'Subcription Notification',
            description:
              'You can enable-disable all your subscription related emails and in app notifications here.',
            side: 'left',
            align: 'start',
          },
        },
        {
          element: '#card-notification',
          popover: {
            title: 'Card Notification',
            description:
              'You can enable-disable all your card related emails and in app notifications here.',
            side: 'bottom',
            align: 'end',
          },
        },
      ],
      // Triggered when the last step is deselected, so we can complete the tour
      onDestroyStarted: async (_, options) => {
        if (options.element === '#card-notification') {
          onCompleteTour();
        } else {
          await guideTourComplete({ status: '1' });
        }
        tour.destroy();
      },
    });

    tour.drive();
  };

  // Navigate to the next page and update the step
  const onCompleteTour = async () => {
    setTourStep(8);
    await guideTourComplete({ status: '1' });
  };
  return (
    <DefaultLayout title="Profile Edit">
      {settingDataLoading ? (
        <Loader />
      ) : (
        <>
          <div className="max-w-full mx-auto">
            {isSaveInfoSuccess && (
              <div className="absolute top-22 right-0 flex w-auto border-l-6 border-[#34D399] bg-[#34D399] bg-opacity-[15%] py-2 px-4 sm:pr-20 shadow-md slideInFromTop-animate">
                <div className="w-full">
                  <h5 className=" text-md font-semibold text-black dark:text-[#34D399] ">
                    Settings saved successfully!
                  </h5>
                </div>
              </div>
            )}
            <div className="bg-white shadow-md w-full rounded-lg mt-2">
              <div className=" flex w-full justify-between flex-wrap">
                <div className="p-6 flex flex-col w-full">
                  <div className="w-full">
                    <div className="mt-4">
                      <div>
                        <div className="text-black">
                          <p className="font-bold text-lg">
                            Notification Settings
                          </p>
                          <p className="text-sm font-medium">
                            We may still send you important notifications about
                            your account outside of your notification settings.
                          </p>
                        </div>
                        <hr className="border-stroke  mt-6" />

                        <div
                          id="subscription-notification"
                          className="grid grid-cols-1 sm:grid-cols-2 mt-6 gap-3"
                        >
                          <div className="">
                            <p className="text-black font-semibold">
                              Subscriptions
                            </p>
                            <p className="text-black text-sm break-words">
                              These are notifications for when user purchase
                              their subcscription.
                            </p>
                          </div>
                          <div>
                            <label
                              htmlFor="subscriptionEmail"
                              className="cursor-pointer flex gap-2"
                            >
                              <div className="relative">
                                <input
                                  type="checkbox"
                                  id="subscriptionEmail"
                                  className="sr-only"
                                  checked={
                                    checkedState.subscriptionEmail || true
                                  }
                                  onChange={() =>
                                    toggleCheckbox('subscriptionEmail')
                                  }
                                />
                                <div
                                  className={`flex h-4 mt-1 w-4 items-center justify-center rounded-sm border border-[#DFC749] ${
                                    checkedState.subscriptionEmail &&
                                    'border-[#DFC749] bg-[#DFC749] dark:bg-transparent'
                                  }`}
                                >
                                  <span
                                    className={`opacity-0 ${checkedState.subscriptionEmail && '!opacity-100'}`}
                                  >
                                    <svg
                                      width="11"
                                      height="8"
                                      viewBox="0 0 11 8"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                        fill="#ffff"
                                        stroke="#ffff"
                                        strokeWidth="0.4"
                                      ></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <p className="text-black font-medium">Email</p>
                            </label>
                            <label
                              htmlFor="subscriptionInApp"
                              className="cursor-pointer flex gap-2"
                            >
                              <div className="relative">
                                <input
                                  type="checkbox"
                                  id="subscriptionInApp"
                                  className="sr-only"
                                  checked={checkedState.subscriptionInApp}
                                  onChange={() =>
                                    toggleCheckbox('subscriptionInApp')
                                  }
                                />
                                <div
                                  className={`flex h-4 mt-1 w-4 items-center justify-center rounded-sm border border-[#DFC749] ${
                                    checkedState.subscriptionInApp &&
                                    'border-[#DFC749] bg-[#DFC749] dark:bg-transparent'
                                  }`}
                                >
                                  <span
                                    className={`opacity-0 ${checkedState.subscriptionInApp && '!opacity-100'}`}
                                  >
                                    <svg
                                      width="11"
                                      height="8"
                                      viewBox="0 0 11 8"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                        fill="#ffff"
                                        stroke="#ffff"
                                        strokeWidth="0.4"
                                      ></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <p className="text-black font-medium">In-app</p>
                            </label>
                          </div>
                        </div>

                        <hr className="border-stroke  mt-6" />

                        <div
                          id="card-notification"
                          className="grid grid-cols-1 sm:grid-cols-2 mt-6 gap-3"
                        >
                          <div className="">
                            <p className="text-black font-semibold">
                              Digital Cards
                            </p>
                            <p className="text-black text-sm break-words">
                              These are notifications for when user create or
                              update their digital business cards.
                            </p>
                          </div>
                          <div>
                            <label
                              htmlFor="digitalCardEmail"
                              className="cursor-pointer flex gap-2"
                            >
                              <div className="relative">
                                <input
                                  type="checkbox"
                                  id="digitalCardEmail"
                                  className="sr-only"
                                  checked={checkedState.digitalCardEmail}
                                  onChange={() =>
                                    toggleCheckbox('digitalCardEmail')
                                  }
                                />
                                <div
                                  className={`flex h-4 mt-1 w-4 items-center justify-center rounded-sm border border-[#DFC749] ${
                                    checkedState.digitalCardEmail &&
                                    'border-[#DFC749] bg-[#DFC749] dark:bg-transparent'
                                  }`}
                                >
                                  <span
                                    className={`opacity-0 ${checkedState.digitalCardEmail && '!opacity-100'}`}
                                  >
                                    <svg
                                      width="11"
                                      height="8"
                                      viewBox="0 0 11 8"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                        fill="#ffff"
                                        stroke="#ffff"
                                        strokeWidth="0.4"
                                      ></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <p className="text-black font-medium">Email</p>
                            </label>
                            <label
                              htmlFor="digitalCardInApp"
                              className="cursor-pointer flex gap-2"
                            >
                              <div className="relative">
                                <input
                                  type="checkbox"
                                  id="digitalCardInApp"
                                  className="sr-only"
                                  checked={checkedState.digitalCardInApp}
                                  onChange={() =>
                                    toggleCheckbox('digitalCardInApp')
                                  }
                                />
                                <div
                                  className={`flex h-4 mt-1 w-4 items-center justify-center rounded-sm border border-[#DFC749] ${
                                    checkedState.digitalCardInApp &&
                                    'border-[#DFC749] bg-[#DFC749] dark:bg-transparent'
                                  }`}
                                >
                                  <span
                                    className={`opacity-0 ${checkedState.digitalCardInApp && '!opacity-100'}`}
                                  >
                                    <svg
                                      width="11"
                                      height="8"
                                      viewBox="0 0 11 8"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                        fill="#ffff"
                                        stroke="#ffff"
                                        strokeWidth="0.4"
                                      ></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <p className="text-black font-medium">In-app</p>
                            </label>
                          </div>
                        </div>
                        <div className="flex flex-col sm:flex-row justify-center sm:justify-end items-center mt-5">
                          <div className="w-full sm:w-auto">
                            <button
                              className="bg-yellowtacx text-sm md:text-base font-[500] rounded-lg text-[#000000] w-full sm:w-[165px] h-[40px] hover:bg-[#594255] hover:text-[#ffffff] transition-all duration-200"
                              onClick={handleNotificationSettings}
                              disabled={notificationSaveLoading}
                            >
                              {notificationSaveLoading ? (
                                <div
                                  role="status"
                                  className="flex items-center justify-center"
                                >
                                  <div className="inline-block h-4 w-4 mr-2 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
                                </div>
                              ) : (
                                <span>Save Settings</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </DefaultLayout>
  );
};
export default UserSetting;
